<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="breadcumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.href"
              class="text-subtitle-2 crumb-item"
              :disabled="item.disabled"
              exact
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- style="border-top: 3px solid #009688" -->
        <v-card
          class="elevation-12 rounded-l"
        >
          <v-alert dense color="#57c0ac" class = "ma-0">
            <!-- <v-card-title style="color: #219989">
              {{ this.titleCard }} . {{ date }}
            </v-card-title> -->
            <v-card-title>
              <v-row>
                <v-col cols="6">
                  <span class="font-color">{{ this.titleCard }}.   {{ date }}</span>
                </v-col>
                <v-col cols="6" class="text-end font-color">
                  {{ "Choose Table" }}
                </v-col>
              </v-row>
            </v-card-title>
          </v-alert>
          <v-card-text class="ma-0" style="padding:8px">
            <!-- <v-container fluid class = "ma-4"> -->
              <!-- <v-row> -->
                <!-- <v-col cols="12"> -->
                  <!-- <v-card-text class="ma-0"> -->
                    <v-container fluid>
                      <!-- <v-row justify="center">
                        <v-col cols="12">
                          <h4
                            class="text-center"
                            style="
                              font-weight: 600;
                              color: #009688;
                              margin-bottom: 15px;
                              margin-top: 2px;">
                            {{ "Choose Table" }}
                          </h4>
                          <v-divider
                            style="
                              border-top: 3px solid #009688;
                              margin-top: 5px;"></v-divider>
                        </v-col>
                      </v-row> -->
                      <v-row>
                        <v-col cols="12" md="2" class="mb-3" v-for="(v, i) in products" :key="i">
                          <template>
                            <!-- :class ="'' === v.stat ? 'ayola--text square-card': 'pink white--text square-card' " -->
                            <!-- :class ="'' === v.stat ? 'square-card': 'ayola-color square-card'" -->
                            <v-card 
                              
                              :color="'' === v.stat ? '': '#57c0ac' ">
                              <!-- <v-card-text 
                                class="text-center"  
                                :style ="'' === v.stat ? 'color:#e91e63;': 'color:white'" >
                                {{ v.title }}
                              </v-card-text> -->
                                <!-- <img src="../../../assets/img/meja2.png" width="100%"/> -->
                                <v-card-text></v-card-text>
                                
                              <v-spacer></v-spacer>
                              <v-container fluid class="parent">
                                <v-col cols="12">
                                  <v-card-text 
                                    class="text-center mb-10"  
                                    :style ="'' === v.stat ? 'color:#57c0ac;': 'color:white'" 
                                  >
                                    {{ v.title }}
                                  </v-card-text>
                                  <v-btn 
                                    style="height: 43px"
                                    block
                                    :class="'' === v.stat ? 'white--text' : 'white'"
                                    :color="'' === v.stat ? '#57c0ac': '' "
                                    @click="openDialog(v)">
                                    Order
                                  </v-btn>
                                </v-col>
                              </v-container>
                            </v-card>
                          </template>
                        </v-col>
                      </v-row>
                      <v-divider style="border-top: 3px solid #009688; margin-top: 5px"></v-divider>
                      <br />
                      <v-dialog
                        v-model="dialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition">
                        <v-container fluid>
                          <v-row>
                            <v-col cols="md-8">
                              <v-col cols="md-12">
                                <v-alert dense color="#e0f3f1">
                                <div class="d-flex">
                                <strong style="color: #219989">Menu Type<strong style="color:red;">*</strong></strong><v-spacer></v-spacer>
                                </div>
                                </v-alert>
                                <v-sheet class="mx-auto mb-lg-3 rounded-lg">
                                  <v-slide-group multiple show-arrows>
                                    <v-slide-item
                                      v-for="(order, i) in menuCategory"
                                      :key="i"
                                    >
                                      <v-btn
                                        class="mx-2 border-12"
                                        active-class="pink white--text"
                                        depressed
                                        :input-value="
                                          order_type === order.descr
                                            ? true
                                            : false
                                        "
                                        @click="getCategoryId(order)"
                                      >
                                        {{ order.descr }}
                                      </v-btn>
                                    </v-slide-item>
                                  </v-slide-group>
                                </v-sheet>
                              </v-col>
                              <!-- menu category -->
                              <v-col cols="md-12">
                                <v-alert dense color="#e0f3f1">
                                <strong style="color: #219989">Menu Category</strong>
                                </v-alert>
                                <v-container fluid>
                                  <v-card elevation="5" class="rounded-lg">
                                    <v-slide-group show-arrows>
                                      <v-slide-item
                                        v-for="(cetegory, i) in commodityDatas"
                                        :key="i"
                                      >
                                        <v-card
                                          :class="category === cetegory.com_desc ? 'pink white--text': ''"
                                          class="d-flex align-center rounded-lg mx-2"
                                          height="170"
                                          @click="onCategoryClick(cetegory)"
                                          flat>
                                          <v-row>
                                            <v-col cols="12" sm="12">
                                              <v-list-item
                                                three-line
                                                class="text-center"
                                              >
                                                <v-list-item-content>
                                                  <div
                                                    align="center"
                                                    justify="center"
                                                    color="success"
                                                  >
                                                    <v-img
                                                      max-height="120"
                                                      max-width="120"
                                                      contain
                                                      :src="getImgUrl(cetegory.icon)"
                                                    ></v-img>
                                                  </div>
                                                  <!-- <v-list-item-subtitle
                                                    :class="
                                                      category ===
                                                      cetegory.com_desc
                                                        ? 'white--text'
                                                        : ''
                                                    "
                                                    class="caption mt-4"
                                                  >
                                                    {{ cetegory.com_desc }}
                                                  </v-list-item-subtitle> -->
                                                </v-list-item-content>
                                              </v-list-item>
                                            </v-col>
                                          </v-row>
                                        </v-card>
                                      </v-slide-item>
                                    </v-slide-group>
                                  </v-card>
                                </v-container>
                              </v-col>
                              <!-- menu list -->
                              <v-col cols="md-12">
                                <v-alert dense color="#e0f3f1">
                                  <strong style="color: #219989">Menu List</strong>
                                </v-alert>
                                <v-container fluid>
                                  <v-row>
                                    <v-card elevation="5" class="rounded-lg">
                                      <v-slide-group show-arrows>
                                        <v-slide-item
                                          v-for="(dataMenu, i) in menuListDatas"
                                          :key="i"
                                        >
                                          <v-card>
                                            <v-col md="12" class="mb-1">
                                              <v-card
                                                max-width="120"
                                                max-heith="120"
                                                align="center"
                                              >
                                                <img
                                                  v-if="
                                                    dataMenu.file_name === ''
                                                  "
                                                  max-height="100"
                                                  max-width="100"
                                                  contain
                                                  src="https://images.unsplash.com/photo-1626314096024-ad9bcfb3a58a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                                                  width="100%"
                                                />
                                                <img
                                                  v-else
                                                  :src="
                                                    getImage(
                                                      path,
                                                      dataMenu.file_name
                                                    )
                                                  "
                                                  height="120"
                                                  width="120"
                                                  contain
                                                />
                                                <v-card-text class="b"
                                                  >{{ dataMenu.description
                                                  }}<br />
                                                  {{ dataMenu.price1_net }}
                                                </v-card-text>
                                                <v-btn
                                                  block
                                                  small
                                                  class="border-6 text-white"
                                                  style="height: 35px"
                                                  color="green"
                                                  @click="addItem(dataMenu)"
                                                >
                                                  Add to Cart
                                                </v-btn>
                                              </v-card>
                                            </v-col>
                                          </v-card>
                                        </v-slide-item>
                                      </v-slide-group>
                                    </v-card>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-col>
                            <!-- shoping cart -->
                            <v-col cols="md-4">
                              <v-container fluid>
                                <v-row>
                                  <v-card>
                                    <br />
                                    <v-alert dense color="#e0f3f1">
                                      <div class="d-flex">
                                      <strong style="color: #219989">Shopping Cart</strong><v-spacer></v-spacer>
                                      <v-btn
                                        :disabled="(cartItem.length != 0 && doc_id != '') ? false : true "
                                        color="pink text-white" 
                                        @click="opendialogTable()">
                                        {{ tableTitle }}
                                      </v-btn>
                                      <!-- <strong style="color: #219989"><strong style="color:red">{{ tableTitle }}</strong></strong> -->
                                       </div>
                                    </v-alert>
                                    <v-list
                                      class="overflow-y-auto mb-2"
                                      style="max-height: 497px">
                                      <v-list-item
                                        v-for="(item, index) in cartItem"
                                        :key="index">
                                        <v-card
                                          elevation="5"
                                          width="100%"
                                          class="mb-1">
                                          <v-btn
                                            class="color: pink"
                                            fab
                                            dark
                                            small
                                            @click="removeItem(index)">
                                            <v-icon> mdi-close-circle </v-icon>
                                          </v-btn>
                                          <v-card-text
                                            class="d-flex justify-space-between">
                                            <div>
                                              <strong class="text-success">{{
                                                item.description
                                              }}</strong>
                                              <br />
                                              <strong>Unit Price = </strong>
                                              <strong>Rp. </strong> {{item.price1_net_formated}} <br />
                                              <strong>Total Price = </strong>
                                              <strong>Rp. </strong> {{item.totalPricePerItemFormated}} <br />
                                              <!-- <strong class="text-success">Comment's or Suggestions:</strong> -->
                                              <v-text-field 
                                                v-model="item.remark"
                                                label="Remark"
                                                :rules="rules"
                                                counter
                                                maxlength="100"
                                                hint="Max 100 characters"
                                                >
                                              </v-text-field>
                                              <v-card-actions>
                                                <v-btn
                                                  outlined
                                                  class="mb-n4 add"
                                                  @click="decrement(index)">
                                                  <v-icon
                                                    small
                                                    color="green"
                                                    
                                                  >
                                                    mdi-minus
                                                  </v-icon>
                                                </v-btn>
                                                <strong
                                                  class="mx-2 mt-5"
                                                  v-text="item.qty"
                                                ></strong>
                                                <v-btn
                                                  outlined
                                                  class="mb-n4 add"
                                                  @click="increment(index)"
                                                >
                                                  <v-icon
                                                    color="green"
                                                    
                                                  >
                                                    mdi-plus
                                                  </v-icon>
                                                </v-btn>
                                              </v-card-actions>
                                            </div>
                                            <v-avatar size="45">
                                              <img
                                                v-if="item.icon === ''"
                                                src="https://plus.unsplash.com/premium_photo-1670044020182-0c4375380b14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1615&q=80"
                                                alt="Yo"
                                              />
                                              <img
                                                v-else
                                                :src="getImgUrl(item.icon)"
                                                alt="Yo"
                                              />
                                              <span style="color: white"
                                                >{{ item.amount }} +</span
                                              >
                                            </v-avatar>
                                          </v-card-text>
                                        </v-card>
                                      </v-list-item>
                                    </v-list>
                                    <strong style="color: #219989">Sub Total = </strong>
                                    <strong style="color: #219989">Rp. {{subTotalFormated}}</strong><br />
                                    <strong style="color: #219989">PPN {{ppn}}% = {{ppnAmountFormated}}</strong>
                                    <!-- <strong style="color: #219989"> %</strong> -->
                                    <br />
                                    <strong style="color: #219989">Service Charge {{serviceCharge}}% = </strong>
                                    <strong style="color: #219989">Rp. {{serviceChargeAmountFormated}}</strong><br />
                                    <strong style="color: #219989">Grand Total = </strong>
                                    <strong style="color: #219989">Rp. {{grandTotalFormated}}</strong><br />
                                    <v-row
                                      class="mb-1 mt-120"
                                      align="center"
                                      justify="center">
                                      <v-col cols="9">
                                        <v-btn
                                          block
                                          :disabled="cartItem.length == 0 ? true : false "
                                          large
                                          class="pink mt-5 text-white"
                                          style="height: 60px"
                                          color="pink"
                                          @click="openDialogorder()"
                                          >Order
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                </v-row>
                              </v-container>
                            </v-col>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <!-- <v-col cols="2">
                                <v-btn
                                  block
                                  small
                                  class="border-8 text-white"
                                  style="height: 45px"
                                  color="success"
                                  @click="emptyCart()"
                                  >Empty Cart
                                  <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                              </v-col> -->
                              <v-col cols="4">
                                <v-btn
                                  block
                                  small
                                  class="border-8 text-white"
                                  style="height: 45px"
                                  color="primary"
                                  @click="close"
                                  >Close
                                  <v-icon>mdi-close-box</v-icon>
                                </v-btn>
                              </v-col>
                            </v-card-actions>
                          </v-row>
                          <v-divider style="border-top: 3px solid #009688; margin-top: 5px;"></v-divider>
                        </v-container>
                      </v-dialog>
                      <v-dialog v-model="dialog2"
                        hide-overlay
                        max-width="600"
                        transition="dialog-bottom-transition">
                        <template>
                         <v-card>
                          <v-card-title class="text-center" style="background-color:#009688;">
                          <v-row align="center" justify="center">
                              <v-col cols="12">
                                  <h5 style="font-weight:200; color: #fff;">
                                  Confirm Order
                                  </h5>
                              </v-col>
                          </v-row>  
                          </v-card-title>
                            <v-card-text>
                            <v-container fluid class="fill-height">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" mb="5">
                                        <h6 style="margin-top: 8px;">
                                            Order Sesuai Pesanan ?
                                        </h6>
                                        <h6 style="margin-top: 8px">Pastikan sesuai dengan pesanan Customer !</h6>
                                    </v-col>
                                </v-row> 
                            </v-container>
                          </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn style="color:#ffffff; text-white" color="#009688" @click="orderCart()">Yes</v-btn>
                                <v-btn style="color:#ffffff; text-white" color="#009688"  @click="close2">No</v-btn>
                            </v-card-actions>
                         </v-card>
                        </template>
                      </v-dialog>
                    <v-dialog v-model="dialog3"
                        hide-overlay
                        max-width="300"
                        transition="dialog-bottom-transition">
                        <template>
                         <v-card>
                          <v-card-title class="text-center" style="background-color:#009688;">
                          <v-row align="center" justify="center">
                              <v-col cols="12">
                                  <h5 style="font-weight:200; color: #fff;">
                                  Change Table
                                  </h5>
                              </v-col>
                          </v-row>  
                          </v-card-title>
                            <v-card-text>
                            <v-container fluid class="fill-height">
                                <v-row>
                                    <!-- <v-col cols="6" xs="6" sm="6" md="6" mb="5">
                                        <h6 style="margin-top: 8px;">
                                            Table
                                        </h6>
                                        
                                    </v-col> -->
                                    <v-col cols="12" xs="12" sm="12" md="12" mb="5">
                                      <v-autocomplete
                                          style="margin-top: 8px"
                                          v-model="newTable"
                                          :items="products"
                                          item-text="title"
                                          item-value="id"
                                          dense
                                          filled
                                          label="To Table">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row> 
                            </v-container>
                          </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn style="color:#ffffff; text-white" color="#009688" @click="changeTable()">Yes</v-btn>
                                <v-btn style="color:#ffffff; text-white" color="#009688"  @click="close2">No</v-btn>
                            </v-card-actions>
                         </v-card>
                        </template>
                      </v-dialog>
                       <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    </v-snackbar>
                    </v-container>
                  <!-- </v-card-text> -->
                <!-- </v-col> -->
              <!-- </v-row> -->
            <!-- </v-container> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { orderApiAyola } from "../../../backend-api/ayola/order/order-api-ayola";
import { backendApi } from "../../../backend-api/backend-api-sr";
export default {
  name: "OrderDashboardAyola",
  components: {},
  props: {
    whId: String,
  },
  data() {
    return {
      rules: [v => v.length <= 100 || 'Max 100 characters'],
      isDisabled: true,
      loading: false,
      snackbar: {
      color: null,
      icon: null,
      mode: null,
      position: "top",
      text: null,
      timeout: 7500,
      title: null,
      visible: false
      },
      breadcumbs: [],
      cartItem: [],
      path: "",
      products: [],
      gambar: [
                {
                    status: 'OPEN',
                    value: 'H'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
      ],
      date: "",
      date_from: "",
      start_date: "",
      // Table: 3,
      tableData: [],
      tableTitle:'',
      dialog: false,
      dialog2: false,
      dialog3:false,
      bpm: 1,
      bpm: "",
      // reset:"",
      category: "Meat",
      order_type: "Food",
      order: "",
      tableLayout: [],
      menuLayout: [],
      selected:[2],
      totalTable: 4,
      totalMenu: 4,
      tableNumber: 0,
      doc_id: '',
      room_id: 0,
      table_id: 0,
      salesId:'',
      catId: ["3", "4"],
      comId: ["0"],
      comIcon: "",
      commodities: [],
      commodityDatas: [],
      menuCategory: [],
      menuList: [],
      menuListDatas: [],
      grandTotal:0,
      subTotal:0,
      grandTotalFormated:0,
      subTotalFormated:0,
      ppn:0,
      serviceCharge:0,
      ppnAmount:0,
      serviceChargeAmount:0,
      ppnAmountFormated:0,
      serviceChargeAmountFormated:0,
      orderResult:'',
      newTable:'',
    };
  },

  methods: {
    getCategoryId(data) {
      this.order_type = data.descr;
      this.catId = [data.cat_id];
      this.getCommodity();
    },
    onCategoryClick(value) {
      this.category = value.com_desc;
      this.catId = [value.cat_id];
      this.comid = [value.com_id];
      this.comIcon = value.icon;
      console.log(this.comIcon);
      this.getListMenu();
    },
    onButtonClick(item) {
      console.log("click on " + item.no);
    },
    getImage(imagePath, fileName) {
      console.log(imagePath);
      console.log(fileName);
      return process.env.VUE_APP_URL + "/" + imagePath + fileName;
    },
    getImgUrl: function (imagePath) {
      return require("@/assets/img/" + imagePath);
    },
    openDialog(value) {
      this.dialog = true;
      this.tableData = value;
      this.tableTitle = this.tableData.title;
      this.table_id = (this.whId === 'EM' || this.whId === 'TS')?this.tableData.id:0;
      this.room_id = (this.whId === 'RS')?this.tableData.id:0;
      console.log(this.table_id)
      console.log(this.room_id)
      this.getCurrentOrder();
    },
    openDialogorder(){
      this.dialog2 = true;
    },
    close2(){
      this.dialog2 = false
      this.dialog3 = false     
    },
    opendialogTable(){
      this.dialog3 = true;
    },  
    
    emptyCart() {
      this.cartItem=[];
      this.subtotal=0;
      this.subTotalFormated=0;
      this.ppnAmount=0;
      this.ppnAmountFormated=0;
      this.serviceChargeAmount=0;
      this.serviceChargeAmountFormated=0;
      this.grandTotal=0;
      this.grandTotalFormated=0;
      this.doc_id = '';
    },
    close() {
      // TODO cek status apakah punya doc id, bila ya cart item = 0 hapus headernya
      if(this.doc_id != ''){
        if(this.cartItem.length == 0){
          // Todo bikin delete header disini
        }
      }
      this.dialog = false;
      this.doc_id = '';
      this.emptyCart();
      this.commodities =[];
      this.commodityDatas =[];
      this.menuList =[];
      this.menuListDatas =[];

    },
    addItem(value) {
     
      let price = 1 * parseInt(value.price1_net.replace(',',''));
      console.log(this.doc_id)
      console.log('ass item')
      console.log(value)
      console.log(value.remark)
      if(value.remark == undefined){
        value.remark = ''
      }
      this.cartItem.push({
        office_id: "1",
        doc_id: "",
        tr_seq: "",
        stat: "K",
        stat_two: "0",
        article_id: value.article_id,
        art_price: value.art_price,
        cat_id: value.cat_id,
        com_id: value.com_id,
        price1: value.price1,
        price1_net: value.price1_net.replace(',', ''),
        price1_net_formated: value.price1_net,
        ppn: this.ppn,
        service_charge: this.serviceCharge,
        totalPricePerItem: value.price1_net.replace(',', ''),
        totalPricePerItemFormated : value.price1_net,
        description: value.description,
        icon: this.comIcon,
        qty: 1,
        uom: value.uom,
        remark: value.remark,
      });
      this.calculateTotalOrder();
    },
    removeItem(value){
     // console.log(this.removeItem)
     // console.log("remove item");
      let temp = this.cartItem.at(value);
      //console.log(temp.stat)
      if (temp.stat === 'O'){
       // console.log(temp);
        this.cartItem.splice(value, 1);
        this.calculateTotalOrder();
        if(temp.doc_id != "" && temp.doc_id != null){
          // TODO delte item dr db
          console.log("delete item dr db")
          this.deleteItem(temp);
          this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 1000,
                    title: "Successfully",
                    text: "Successfully Delete Item",
                    visible: true
                    };
          }
        }
    },
    increment(value){
      console.log("increment");
      let temp = this.cartItem.at(value);
      console.log(temp);
      if (temp.stat === 'O'){
        let qty = temp.qty + 1;
        if(qty <= 0){
          this.cartItem.splice(value, 1);
        } else {
          this.cartItem.at(value).qty = qty;
          let price = qty * parseInt(this.cartItem.at(value).price1_net.replace(',',''));
          this.cartItem.at(value).totalPricePerItem = price;
          this.cartItem.at(value).totalPricePerItemFormated = new Intl.NumberFormat().format(price);
        }
        this.calculateTotalOrder();
      }
    },
    decrement(value){
      console.log("decrement");
      let temp = this.cartItem.at(value);
      console.log(temp);
      if (temp.stat === 'O'){
        let qty = temp.qty - 1;
        if(qty <= 0){
          this.cartItem.splice(value, 1);
          if(temp.doc_id != "" && temp.doc_id != null){
            // TODO delte item dr db
            console.log("delete item dr db")
            this.deleteItem(temp);
          }
        } else {
          this.cartItem.at(value).qty = qty;
          let price = qty * parseInt(this.cartItem.at(value).price1_net.replace(',',''));
          this.cartItem.at(value).totalPricePerItem = price;
          this.cartItem.at(value).totalPricePerItemFormated = new Intl.NumberFormat().format(price);
        }
        this.calculateTotalOrder();
      }
    },

    calculateTotalOrder(){
      this.subTotal = 0;
      this.ppnAmount = 0;
      this.serviceChargeAmount = 0;
      this.grandTotal = 0;
      if(this.cartItem.length == 0){
        this.subTotalFormated = 0;
        this.ppnAmountFormated = 0;
        this.serviceChargeAmountFormated = 0;
        this.grandTotalFormated = 0;
      }
      let tempVal;
      this.cartItem.forEach((element) => {
        tempVal = parseInt(element.totalPricePerItem);
        this.subTotal = this.subTotal + tempVal;
        this.ppnAmount = Math.ceil(this.subTotal * this.ppn /100);
        this.serviceChargeAmount = Math.floor(this.subTotal * this.serviceCharge / 100);
        this.grandTotal = this.subTotal + this.ppnAmount + this.serviceChargeAmount;
        this.subTotalFormated = new Intl.NumberFormat().format(this.subTotal);
        this.ppnAmountFormated = new Intl.NumberFormat().format(this.ppnAmount);
        this.serviceChargeAmountFormated = new Intl.NumberFormat().format(this.serviceChargeAmount);
        this.grandTotalFormated = new Intl.NumberFormat().format(this.grandTotal);
      });
    },
    async changeTable(){
      var reqBody = {
        office_id:'1',
        doc_id: this.doc_id,
        c_id : this.table_id,
        c_id_next : this.newTable,
        sales_id : this.salesId,
      };
      var tempRes = await orderApiAyola.moveTable(reqBody);
      if (tempRes.data.status_code == 200) {
        this.snackbar = {
          color: "success",
          icon: "mdi-checkbox-marked-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Success",
          text: tempRes.data.message,
          visible: true
        };
        //this.$store.dispatch('setOverlay', true)
        this.table_id = this.newTable;
        this.tableTitle = `Table ${this.newTable}`;
        this.dialog3 = false;
        this.getTableLayout();
      } else if (tempRes.data.status_code == 400) {
        this.snackbar = {
          color: "error",
          icon: "mdi-checkbox-marked-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Error",
          text: tempRes.data.message,
          visible: true
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-checkbox-marked-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Error",
          text: tempRes.data.message,
          visible: true
        };
      }
    },
    async getTableLayout() {
      this.subPath = `?wh_id=${this.whId}`;
      this.tableLayout = await orderApiAyola.fetchRestoTable(this.subPath);
      var reqBody = {
        office_id: '1',
        sales_id: this.salesId,
        stat: ['O', 'K', 'F']
      };
      var temp = [];
      orderApiAyola.fetchTableStatus(reqBody).then(
        (response) => {
          temp = response.data.data;
          console.log(temp);
          this.totalTable = this.tableLayout.data.data[0].int1 * this.tableLayout.data.data[0].int2;
          this.products = [];
          for (let i = 0; i < this.totalTable; i++) {
            const docId = temp.find(temp => temp.c_id == (i +1));
            var stat = (docId == null)?"":docId['stat'];
            this.products.push({
              id: i + 1,
              img: "p1.png",
              title: `Table ${i + 1}`,
              stat : stat,
            });
          }
          console.log(this.products)
        } 
      );
      
    },
    async getCategory() {
      this.menuCategory = [];
      var reqBody = {
        cat_id: this.catId,
        active_flag: "Y",
      };
      this.menuCategory = await orderApiAyola.fetchCategory(
        "",
        reqBody,
        false,
        false,
        false
      );
      this.menuCategory = this.menuCategory.data.data;
      this.menuCategory.unshift({ cat_id: "0", descr: "ALL" });
      if (this.salesId == "FBSKY"){
        this.menuCategory.push({ cat_id: "10", descr: "OTHER" });
      }
    },
    async getCommodity() {
      this.commodityDatas = [];
      this.menuListDatas = [];
      console.log(this.catId)
      if (this.catId.length == 1 && this.catId[0] == 0) {
        if(this.salesId == 'FBSKY'){
          this.catId = ["3", "4", "10"];
        } else {
          this.catId = ["3", "4"];
        } 
      }
      console.log(this.catId)
      var reqBody = {
        cat_id: this.catId,
        active_flag: "Y",
      };
      this.commodities = await backendApi.fetchCore(
        `/api/ayola/order/commodity`,
        reqBody,
        false,
        false,
        false
      );
      this.commodityDatas = this.commodities.data.data;
      this.commodityDatas = this.commodityDatas.filter(item => !["FOOD", "BEVERAGE"].includes(item.com_desc));
    },
    async getImagePath() {
      let type = "MENU";
      this.subPath = `?img_type=${type}`;
      let temp = await orderApiAyola.fetchRestoImageMenu(this.subPath);
      this.path = temp.data.data.at(0).str2;
    },
    async getListMenu() {
      this.menuList = [];
      if (this.catId.length == 1 && this.catId[0] == 0) {
        this.catId = ["3", "4"];
      }
      var reqBody = {
        cat_id: this.catId,
        com_id: this.comid,
        article_id: ["0"],
        barcode: [""],
        active_flag: "Y",
      };
      this.menuList = await backendApi.fetchCore(
        `/api/ayola/order/resto-menu-list`,
        reqBody,
        false,
        false,
        false
      );
      this.menuListDatas = this.menuList.data.data;
    },
    async getPpn(){
      let temp =  await orderApiAyola.fetchPpn('');
      this.ppn = temp.data.data.at(0).int1;
    },
    async getServiceCharge(){
      let temp =  await orderApiAyola.fetchServiceCharge('');
      this.serviceCharge = temp.data.data.at(0).int1;
    },

    async getCurrentOrder(){
      // this.salesId
      // this.whId
      // this.table_id

       var reqBody = {
          office_id: '1',
          doc_id: '',
          shift_id: this.$store.getters.shift,
          customer_id: this.room_id,
          c_id : this.table_id,
          sales_id : this.salesId,
          stat : ['O', 'K', 'F']
      };
      console.log(reqBody);
      var temp = await orderApiAyola.fetchOrderHeaderDetail(reqBody);
      var tempData = temp.data.data;
      console.log(tempData);
      if (tempData.length > 0){
        this.cartItem=[];
        console.log(tempData.length);
        // this.cartItem = tempData;
        tempData.forEach((element) => {
          console.log(element)
          this.doc_id = element.doc_id;
          this.cartItem.push({
            office_id: element.office_id,
            doc_id: element.doc_id,
            tr_seq: element.tr_seq,
            stat: element.stat,
            stat_two: '0',
            article_id: element.article_id,
            art_price: element.art_price,
            cat_id: element.cat_id,
            com_id: element.com_id,
            price1: element.unit_price,
            price1_net: element.price1_net,
            price1_net_formated: new Intl.NumberFormat().format(element.price1_net),
            ppn: element.ppn,
            service_charge: element.service_charge,
            totalPricePerItem: element.price1_net * element.tr_qty,
            totalPricePerItemFormated: new Intl.NumberFormat().format(element.price1_net * element.tr_qty),
            description: element.description,
            icon: element.icon,
            qty: parseInt(element.tr_qty),
            uom: element.uom,
            remark: element.remark
          })
        });
        this.calculateTotalOrder();
        console.log(this.cartItem)
      }
    },

    async orderCart(){
      //console.log(this.cartItem);
      var reqBody = {
        suffix: this.whId,
        office_id:'1',
        doc_id: this.doc_id,
        tr_type : 'S',
        shift_id : this.$store.getters.shift,
        customer_id : this.room_id,
        c_id : this.table_id,
        sales_id : this.salesId,
        ppn : this.ppn,
        stat : 'K',
        orderItem : this.cartItem,
        po_id :'12345'
      };
      //console.log(reqBody);
      this.orderResult = await orderApiAyola.postOrder(reqBody);
     // console.log(this.loading);
      if (this.orderResult.data.status_code != 200) {
          this.snackbar = {
                    color: "error",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Tai Creating Order",
                    text: "Failed Creating Transaction, Please TryAgain",
                    visible: true
                    };
                    this.dialog2 = false
      } else {
        this.snackbar = {
          color: "success",
          icon: "mdi-checkbox-marked-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Successfully",
          text: "Successfully Creating Transaction",
          visible: true
          };
          //this.$store.dispatch('setOverlay', true)
          this.dialog2 = false;
          this.close();
          this.getTableLayout();
        }
    },

    async deleteItem(value){
      var reqBody = {
        office_id: value.office_id,
        doc_id: value.doc_id,
        tr_seq : value.tr_seq,
        article_id : value.article_id
      };
      this.orderResult = await orderApiAyola.deleteOrderItem(reqBody);
     // console.log(this.orderResult);
      if (this.orderResult.data.status_code != 200) {
        this.snackbar = {
                    color: "error",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error Delete",
                    text: "Failed Delete Order ! Please Try Again",
                    visible: true
                    };
      } else {
         this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 1000,
                    title: "Successfully",
                    text: "Successfully Delete Order",
                    visible: true
                    };
        // Todo tampilkan snackbar kalau order item berhasil dihapus
      }
    },
  },
  watch: {
  // dialog() {
  //       this.$refs.form.reset()
  //   }
  },
  mounted() {
    //this.$store.dispatch('setOverlay', false)
    this.date = Intl.DateTimeFormat("id", {
      timeZone: "Asia/Jakarta",
      day: "numeric",
      month: "long",
      weekday: "long",
      year: "numeric",
    }).format();
    this.getTableLayout();
    this.getImagePath();
    this.getCategory();
    this.getPpn();
    this.getServiceCharge();
  },

  created() {
    // this.titleCard = this.whId == "TS" ? "Topaz Sky Lounge" : "Emerald Bistro";
    if (this.whId === "TS") {
      this.titleCard = "Topaz Sky Lounge";
      this.salesId = 'FBSKY';
      this.breadcumbs = [
        {
          text: "AYOLA",
          disabled: false,
          href: "/admin/ayala",
        },
        {
          text: "Topaz Sky Lounge",
          disabled: true,
          href: "",
        },
      ];
    } else if (this.whId === "EM") {
      this.titleCard = "Emerald Bistro";
      this.salesId = 'FBEMR';
      this.breadcumbs = [
        {
          text: "AYOLA",
          disabled: false,
          href: "/admin/ayala",
        },
        {
          text: "Emerald Bistro",
          disabled: true,
          href: "",
        },
      ];
    } else {
      this.titleCard = "Room Service";
      this.salesId = 'FBSRV';
      this.breadcumbs = [
        {
          text: "AYOLA",
          disabled: false,
          href: "/admin/ayala",
        },
        {
          text: "Room Service",
          disabled: true,
          href: "",
        },
      ];
    }
  },
};
</script>

<style scoped>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
  }
  div.b {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  } 
  .center-text {
    text-align: center;
  }
  .font-color{
    color: #ffffff;
  }
  .ayola-color{
    color: #57c0ac;
  }
  .ayola-background-color{
    color: #57c0ac;
  }
  .table-card{
    height: 300px; 
    overflow:auto;
  }
  .table-header-font{
    font-size: smaller; 
    color: #ffffff; 
    padding:0;
  }
  .ayola--text {
    color: 57c0ac;
    font-size: 18px;
    font-weight: bold;
  }
  .square-card {
    width: 200px;
    height: 200px;
    text-align: center;
  }
  .parent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>